import Title from "../Title";

import styles from "./styles.module.scss"
import { IoIosArrowDown } from "react-icons/io";




export default function Actuator(props = { data: Array }) {
  const { actuator } = props.data;
  return (
    <div>
      <Title>Áreas de atuação</Title>
      <div
        className={styles.externalContainer}>
        <div className={styles.centralizeCards}>

          {actuator.map((act, i) => (
            <div key={i} className={styles.container}>
              <input type="checkbox" id={"ckbx" + i} className={styles.checkBox} defaultChecked />
              <label htmlFor={"ckbx" + i} className={styles.iconCard}>
                <div>
                  <img src={act.icon} alt={act.text} />
                  <p>{act.text}</p>
                  <IoIosArrowDown />
                </div>
              </label>
              <label htmlFor={"ckbx" + i} className={styles.textContent}>
                {act.description}
              </label>
            </div>
          ))
          }
        </div>
      </div >
    </div >
  );
}
