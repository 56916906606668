import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  background-color: #eabfb9;

  min-height: 570px;

  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;

  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
`;
const Content = styled.div`
  max-width: 1280px;
  padding-inline: 20px;
  width: 100%;

  display: flex;
  align-items: center;

  justify-content: space-around;

  position: relative;

  @media (max-width: 796px) {
    align-items: flex-start;
    justify-content: flex-end;
  }
`;
const AnchorContent = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-top: 1rem;

  a {
    border-radius: 16px;
    background-color: #c56575;
    padding: 8px 32px;
    color: #fafafa;
    text-decoration: none;
    font-weight: 700;

    font-size: 20px;
  }

  @media (max-width: 796px) {
    margin-top: 24px;
    a {
      font-size: 16px;
      padding: 10px 24px;
    }
  }
`;

const TextContainer = styled.div`
  max-width: 537px;

  h2 {
    font-size: 64px;
    margin: 0;
    color: #c56575;
  }
  p {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    color: #fafafa;
    opacity: 90%;
  }

  @media (max-width: 796px) {
    max-width: calc(100% - 20px);
    margin-top: 48px;
    h2 {
      font-size: 48px;
    }
    p {
      font-size: 18px;
    }
  }
  @media (max-width: 480px) {
    max-width: calc(100% - 20px);
    margin-top: 48px;
    h2 {
      font-size: 32px;
    }
    p {
      font-size: 16px;
    }
  }
`;

const Risquinho = styled.hr`
  width: 80px;
  margin: 0;
  border-width: 4px;
  border-style: solid;
  border-color: #c56575;

  margin-block: 8px;
`;

const Texture = styled.img`
  opacity: 25%;
  mix-blend-mode: soft-light;
  position: absolute;
  inset: 0;
  z-index: 0;
`;
const Img = styled.img`
  margin: 0;
  padding: 0;
  @media (max-width: 796px) {
    position: absolute;
    bottom: 0;
    left: 20px;

    width: 236px;
    height: 355px;
  }
`;

export default function LaserIntimo() {
  return (
    <Container>
      <Texture src="/texture/pointed.jpg" />
      <Content>
        <Img src="/img/person/vanessa-nobg.png" />

        <TextContainer>
          <h2>Laser Íntimo</h2>

          <Risquinho />
          <p>
            Conheça os protocolos individualizados da tecnologia Laser Íntimo
            para aumentar sua confiança e autoestima visando a saúde da mulher.
          </p>
          <AnchorContent>
            <a href="https://laserintimocuritiba.com.br/">
              conhecer o Laser íntimo
            </a>
          </AnchorContent>
        </TextContainer>
      </Content>
    </Container>
  );
}
