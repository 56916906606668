import styled from "styled-components";
import Title from "./Title";
import { Config } from "../config/Config";

const OutCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
  margin-inline: 1rem;
`;
const BiographyCard = styled.div`
  max-width: 50rem;
  min-width: 20rem;
  display: block;
  h3{
    color: rgb(0,0,0,0.7);
  }
`;
const ProfileCard = styled.div`
  display: block;

  img {
    margin-block: 1rem;
    width: 15rem;
    height: 20rem;
    object-fit: cover;
    border-radius: 1rem;
    box-shadow: 1px 1px 10px rgb(0, 0, 0, 0.5);

    -webkit-transition:all 400ms;
    
    &:hover{

      height:22rem;
    }
  }
  div {
    margin-block: 0.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 1rem;
    padding-inline: 1rem;
    -webkit-transition: all 0.3s;
    cursor: pointer;
    color: white;

    svg{
      width: 1.5rem;
      height: 1.5rem;
      opacity: 80%;
    }


    p {
      font-weight: 600;
    
    }

    &:hover {
      background-color: black;
      color: white;
      opacity: 80%;
    }
  }
`;

export default function Aboutme(props = { data: Object }) {
  const { name, profileimgid, biography } = props.data;


  const getWhatsappContext = Config.socials.find(search => search.name === "WhatsApp")
  return (
    <div>
      <Title >Sobre mim</Title>
      <OutCard>
        <ProfileCard>
          <img src={"img/gallery/" + profileimgid} alt="profile-card" />

          <div
            onClick={(e) => {
              window.location.href = getWhatsappContext.link;
            }}
            style={{ backgroundImage: getWhatsappContext.bgimg }}
          >
            {getWhatsappContext.icon}
            <p>{getWhatsappContext.name}</p>
          </div>

        </ProfileCard>

        <BiographyCard>
          <h3>{name}</h3>
          {biography.map((bio, i) => (
            <p key={"bio_" + i}>{bio}</p>
          ))}
        </BiographyCard>
      </OutCard>
    </div>
  );
}
