import styled from "styled-components";
import { FaWhatsapp } from "react-icons/fa";
import { Config } from "../config/Config";

const Whatsapp = styled.div`
  cursor: pointer;
  height: 4rem;
  width: 4rem;


  background-color: #00e576;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  z-index: 100;
  box-shadow: 1px 1px 10px rgb(0, 0, 0, 0.2);
  -webkit-transition: 0.3s;

  svg {
    height: 2rem;
    width: 2rem;
    color: white;
    margin: auto;
  }

  
  &::before{
      position: absolute;
      width: 0rem;
      overflow: hidden;

      

      -webkit-transition: all 0.9s;
      transform: translateX(0rem);



      background-color: #00e576;
      height: 100%;

      right: 2rem;
      border-radius: 10rem 0 0 10rem;
      z-index: -1;

      display: flex;
      align-items: center;
      content: "";

  }

    &::after{
      color: white;
      font-weight: 600;


      white-space: nowrap;
      -webkit-transition: all 1s ;

      right: 4rem;
      content: "Agende sua consulta!";
      position: absolute;
      width: 0rem;
      overflow: hidden;

      opacity: 0;
    }
  &:hover {
    /* opacity: 50%; */


    &::before{
      width: 10rem;
      padding-inline: 2rem;
    }

    &::after{
      content: "Agende sua consulta!";
      position: absolute;
      width: 10rem;
      overflow: hidden;
      opacity: 1;

    }
  }
`;

const WhatsappContainer = styled.div`
  position: fixed;
  right: 7vw;
  bottom: 7rem;
  width: 15rem;
  display: flex;
  justify-content: flex-end;

`

export default function WhatsappBtn() {
  return (

    <WhatsappContainer>


      <Whatsapp
        onClick={(e) => {
          window.location.href = Config.calls.whatsapp.link;
        }}
      >
        <FaWhatsapp />
      </Whatsapp>

    </WhatsappContainer>

  );
}
